var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',[_vm._v(" Products "),_c('div',{staticClass:"title-vertical-divider"}),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"rgb(255, 34, 65)","dark":"","rounded":"","to":{ name: 'addProduct' }}},[_vm._v(" Add Products ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filteredProducts,"item-key":"id","search":_vm.search},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"id":'descriptionDialog' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"size":"16"}},[_vm._v("mdi-eye")]),_vm._v(" Description ")],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Product Description")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-2"},[_c('p',{domProps:{"innerHTML":_vm._s(item.description)}})])],1)],1)]}},{key:"item.featured_image",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"id":'imageDialog' + item.id,"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"pr-2",attrs:{"size":"16"}},[_vm._v("mdi-eye")]),_vm._v(" Image ")],1)]}}],null,true)},[_c('v-card',[_c('v-card-title',[_vm._v("Featured Image")]),_c('v-divider'),_c('v-card-text',{staticClass:"py-2"},[_c('img',{attrs:{"src":item.featuredImage,"width":"100%","alt":item.name}})])],1)],1)]}},{key:"item.is_completed",fn:function(ref){
var item = ref.item;
return [(!item.is_completed)?_c('div',{staticClass:"d-flex justify-left align-items-center"},[_c('div',{staticClass:"my-2"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","x-small":"","dark":""},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v(" Complete Now ")])],1)]):_vm._e()]}},{key:"item.stock",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.updateStatus(item)}},model:{value:(item.stock),callback:function ($$v) {_vm.$set(item, "stock", $$v)},expression:"item.stock"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left align-items-center"},[_c('div',{staticClass:"my-2"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.edit(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"color":"error","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-4 text-center"},[_c('v-icon',{attrs:{"color":"error","size":"50"}},[_vm._v("mdi-close-circle")]),_c('h2',{staticClass:"font-bold py-4"},[_vm._v(" Are you sure want to delete this Item? ")]),_c('h4',{staticClass:"font-bold text-left"},[_vm._v(" Please be aware that you cannot revert this item in future. ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"px-8",attrs:{"color":"error","dark":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }