export default {
  methods: {
    slugtonameupper(slug) {
      const name = slug.split("_");
      let finalName = "";
      name.forEach((element) => {
        finalName = finalName + element.toUpperCase() + " ";
      });
      return finalName;
    },
  },
};
