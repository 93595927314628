<template>
  <v-container fluid>
    <v-card elevation="5">
      <v-card-title>
        Products
        <div class="title-vertical-divider"></div>
        <v-btn
          color="rgb(255, 34, 65)"
          dark
          rounded
          class="mb-2"
          :to="{ name: 'addProduct' }"
        >
          Add Products
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="filteredProducts"
        item-key="id"
        class="elevation-0"
        :search="search"
      >
        <template #[`item.description`]="{ item }">
          <v-dialog :id="'descriptionDialog' + item.id" width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                <v-icon size="16" class="pr-2">mdi-eye</v-icon> Description
              </v-btn>
            </template>

            <v-card>
              <v-card-title>Product Description</v-card-title>
              <v-divider></v-divider>
              <v-card-text class="py-2">
                <p v-html="item.description"></p>
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template #[`item.featured_image`]="{ item }">
          <v-dialog :id="'imageDialog' + item.id" width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                <v-icon size="16" class="pr-2">mdi-eye</v-icon> Image
              </v-btn>
            </template>

            <v-card>
              <v-card-title>Featured Image</v-card-title>
              <v-divider></v-divider>
              <v-card-text class="py-2">
                <img :src="item.featuredImage" width="100%" :alt="item.name" />
              </v-card-text>
            </v-card>
          </v-dialog>
        </template>
        <template #[`item.is_completed`]="{ item }">
          <div
            v-if="!item.is_completed"
            class="d-flex justify-left align-items-center"
          >
            <div class="my-2">
              <v-btn
                color="primary"
                class="mr-2"
                x-small
                dark
                @click="edit(item.id)"
              >
                Complete Now
              </v-btn>
            </div>
          </div>
        </template>
        <template v-slot:[`item.stock`]="{ item }">
          <v-switch
            @change="updateStatus(item)"
            v-model="item.stock"
          ></v-switch>
        </template>
        <template #[`item.actions`]="{ item }">
          <div class="d-flex justify-left align-items-center">
            <div class="my-2">
              <v-btn
                color="primary"
                class="mr-2"
                fab
                x-small
                dark
                @click="edit(item.id)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn color="error" fab x-small dark @click="deleteItem(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogDelete" max-width="400px">
        <v-card>
          <v-card-text class="pt-4 text-center">
            <v-icon color="error" size="50">mdi-close-circle</v-icon>
            <h2 class="font-bold py-4">
              Are you sure want to delete this Item?
            </h2>
            <h4 class="font-bold text-left">
              Please be aware that you cannot revert this item in future.
            </h4>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" class="px-8" dark @click="deleteItemConfirm"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import slugToName from "../../mixins/SlugToName";
import Swal from "sweetalert2";
export default {
  mixins: [slugToName],
  data() {
    return {
      search: "",
      selected: [],
      expanded: [],
      name: "",
      descriptionDialog: false,
      dialogProduct: false,
      imageDialog: false,
      dialogDelete: false,
      edited: [{ id: "", name: "" }],
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Category", value: "category" },
        { text: "Sub Category", value: "subcategory" },
        { text: "Brand", value: "brand" },
        { text: "SKU", value: "sku" },
        { text: "Description", value: "description" },
        { text: "Featured Image", value: "featured_image" },
        { text: "Incomplete Entry", value: "is_completed" },
        { text: "Out of Stock", value: "stock" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      tab: null,
      tabheaders: [
        "General Features",
        "Camera Features",
        "Connectivity Features",
        "Display Features",
        "Power Supply Features",
        "Processor Features",
        "Storage Features",
        "Warranty Features",
        "Other Features",
      ],
    };
  },
  computed: {
    ...mapState({
      products: (state) => state.products,
    }),
    filteredProducts() {
      return this.products.map((item) => {
        return {
          ...item,
          stock: !item.is_active,
          category: item.category && item.category.name,
          subcategory: item.subcategory && item.subcategory.name,
          brand: item.brand && item.brand.name,
          description: item.details
            ? item.details.description
            : "No Content Found",
        };
      });
    },
  },
  methods: {
    ...mapActions(["deleteProduct", "updateProductStatus"]),
    otherfeatures(id) {
      const product = this.products.filter((item) => item.id === id)[0];
      let arr = [];
      const details = product.details;
      arr.push(details.generalFeatures);
      arr.push(details.cameraFeatures);
      arr.push(details.connectivityFeatures);
      arr.push(details.displayFeatures);
      arr.push(details.powerSupplyFeatures);
      arr.push(details.processorFeatures);
      arr.push(details.storageFeatures);
      arr.push(details.warrantyFeatures);
      arr.push(details.otherFeatures);

      return arr;
    },
    formatKey(item) {
      return this.slugtonameupper(item);
    },

    deleteItem(item) {
      let temp = this.products.filter((product) => product.id === item.id)[0];
      this.editedIndex = this.products.indexOf(temp);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = {};
      });
    },

    deleteItemConfirm() {
      this.loading = true;
      this.deleteProduct(this.editedItem.id)
        .then(() => {
          this.loading = false;
          this.$store.commit("setProducts", this.products);
          this.products.splice(this.editedIndex, 1);
          // Swal.fire("Success!", "Brand Deleted Successfully!", "success");
          this.editedIndex = -1;
        })
        .catch(() => {
          this.loading = false;
          // Swal.fire("Oops...", "Something went wrong!", "error");
          this.editedIndex = -1;
        });
      this.closeDelete();
    },

    edit(id) {
      this.$router.push({
        name: "editProduct",
        params: {
          id: id,
        },
      });
    },
    updateStatus(item) {
      let data = { id: item.id };
      data.is_active = !item.is_active;
      let temp = this.products.filter((product) => product.id === item.id)[0];
      this.editedIndex = this.products.indexOf(temp);
      this.updateProductStatus(data)
        .then((response) => {
          if (this.editedIndex >= 0) {
            Object.assign(this.products[this.editedIndex], response.data.data);
            this.$store.commit("setProducts", this.products);
          } else {
            let temp1 = this.filtered.filter(
              (product) => product.id === response.data.data.id
            )[0];
            let index = this.filtered.indexOf(temp1);
            Object.assign(this.filtered[index], response.data.data);
          }
          this.editedIndex = -1;
          this.flashDeal = {};
        })
        .catch((err) => {
          if (err.response.status == 404) {
            Swal.fire("Oops...", err.response.data, "error");
          } else {
            Swal.fire("Oops...", "Something went wrong!", "error");
            this.editedIndex = -1;
          }
        });
      this.flashDialog = false;
    },
  },
};
</script>
<style lang="scss">
.title-vertical-divider {
  border-left: 2px solid rgba(112, 110, 110, 0.753);
  height: 40px;
  margin: 0 1rem;
}
</style>
